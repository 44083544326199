import React from 'react';
import { useState ,useEffect,useMemo} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import QuestionIcon from '../../components/QuestionIcon';
import ReactTablePopUpMultiSelect from '../../components/ReactTablePopUpMultiSelect';
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import {moment} from 'moment';
import TableRowsSalesPosting from "./TableRowsSalesPosting";
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getUserAllowedPlants} from '../../services/PlantServices';
import { getAllProducts } from '../../services/ProductServices';
import { getAllCustomerMain,getCustomerMainDetails} from '../../services/CustomerMainServices';
import {getAllCustomerSites,getCustomerSiteDetails} from '../../services/CustomerSiteServices';
import { getSalesOrdersForInvoice,getSalesOrderDetails } from '../../services/SalesOrderServices';
import { getDeliveryChallansForInvoice } from '../../services/DeliveryChallanServices';
import { getInvoicenumber } from '../../services/SalesInvoiceServices';
import { showBillingPop, showCustomerSitePop, showProjectrPop, showSalesorderPop, showWorkschedulePop } from './SalesPostingUtils';
import SummaryIcon from '../../components/SummaryIcon';
import { getDisplayDate } from '../../utils/DateUtils';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import NumberSetting from '../../components/NumberSetting';
function SalesInvoice() {
  const [showModal, setShowModal] = useState(false);
  const initial_table_data = {
      records: [],
    };
  const [data, setData] = useState(initial_table_data);
    const location = useLocation(); 
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [Dc, setDc] = useState({});
    const [dcList, setDcList] = useState({});
    const [plants, setPlants] = React.useState([]);
    const[selectedSite,setSelectedSite]= React.useState({})
    const [customers, setCustomers] = useState([]);
    const [sites, setSites] = React.useState([]);
    const[allSalesOrders,setAllSalesOrders] = useState([]);
    const [salesorders,setSalesorders]=React.useState([]);
    const[selectedSalesOrder,setSelectedSalesOrder] = useState({});
    const[selectedCustomers,setSelectedCustomer]=React.useState({})
    const [inProgress, setInProgress] = useState(false);
    const [inputs, setInputs] = useState({});
    const [plant, setPlant] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);  
    const [Appr_so_dtl, setAppr_so_dtl] = React.useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {
      setInputs(values => ({...values,
          ['invoice_date']: getCurrentDate(),
          ['invoice_time']: getCurrentTime(),
      }))
  }, []);
    React.useEffect(() => {
      //Load data for Plant dropdown
      getUserAllowedPlants(cookies)
      .then (
          plantList => {
              
              const plants = plantList.plant_list.filter(obj => obj.status).map(
                  plant => {
                      return { value: plant.id, label: plant.plant_alias }
                  }
              );
              setPlants(plants);
          }
      )
  }, []);
  React.useEffect(() => {
    getAllCustomerMain(cookies)
    .then (
        customerList => {
            
            const customers = customerList.customer_list.filter(obj => obj.status).map(
                customer => {
                    return { value: customer.id, label: customer.name }
                }
            );
            setCustomers(customers);
        }
    )
}, []);
React.useEffect(() => {
getAllCustomerSites(cookies)
.then (
    response => {
        const sites = response.consignee_list.filter(obj => obj.status).map(
            site => {
                return { value: site.id, label: site.name }
            }
        );
        setSites(sites);
    }
)
}, []);

const handleInvoiceDetails = (event) => {
  const name = event.target.name;
  const value = event.target.value;

  if(name === "creation_date"){
      setInputs(values => ({...values, 'invoice_date': event.target.value}))
  } else {
      setInputs(values => ({...values, [name]: event.target.value}))
  }
  
}
const [checkedIds, setCheckedIds] = useState([]);
const handleChangeSalesOrderDetails = (value, event) => {
  console.log(value,"value",event.target.checked,"event.target.checked")
  const isChecked = event.target.checked;
    if (isChecked) {
        setCheckedIds(prevIds => [...prevIds, value]);
    } else {
        setCheckedIds(prevIds => prevIds.filter(item => item !== value));
    }
     // Update the data to reflect the checkbox state
     setData(prevData => ({
      ...prevData,
      records: prevData.records.map(record => 
          record.id === value ? { ...record, isChecked } : record
      )
  }));
} 
const showCustomerDetails = () => {
  if (selectedSalesOrder.id) {
      getCustomerMainDetails(cookies,selectedSalesOrder.consignee.customer.id)
      .then(selectedCustomer => {
          showBillingPop(selectedCustomer)
      })
      }
  }

  const showSiteDetails = () => {
    if (selectedSalesOrder.id) {
        getCustomerSiteDetails(cookies,selectedSalesOrder.consignee.id)
        .then(consignee => {
          showCustomerSitePop(consignee);
        })
      }
      }

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      try{
          if (name === 'plant_id') {
            var moment = require('moment');
            var d=moment(inputs.invoice_date).format("DD-MM-YYYY");
            getInvoicenumber(cookies,value,d)
            .then(response => {
                setIsLoading(false);
                setInputs(values => ({...values, ['invoice_no']: response.invoice_no,['prefix']:response.prefix}));
            })
                  .catch((error) =>
            {
                setIsLoading(false);
              console.log(error.response.data);
              displayError(error.response.data," Failed");
            })
          }
          setInputs(values => ({ ...values, [name]: event.target.value }))
        }
        catch(e)
        {
          displayErrorToast(e);
        } 
    }
    const handleChange4 = (index, evnt)=>{
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
 
      setRowsData(rowsInput);
  }
    const [rowsData, setRowsData] = useState([]);
    const [Invdtls, setInvdtls] = useState([]);
    const handleSubmit = (event) => {
      event.preventDefault();
       var err = 0;
    try{
      if (err == 0) {            //If regex is statisfied condition is valid it accept ,otherwise it is Invalid
        Swal.fire({
          title: 'Do you want to save?',
          showCancelButton: true,
          confirmButtonText: `Yes`,
          cancelButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post('http://127.0.0.1:8000/SalesPosting/', {
              rowsData: rowsData,
              dcCompCode: inputs.dcCompCode,
              wsSlNo: inputs.wsSlNo
            },
              {
                headers: {
                  'Authorization': `Token ${cookies['myToken']}`
                }
              })
              .then(function (response) {
                console.log(JSON.stringify(response) + "response");
              }).catch(function (error) {
                console.log(JSON.stringify(error) + "error");
              })
            Swal.fire('Saved!', '', 'success')
            Cancel();
          }
          else if (result.isDismissed) {
            Swal.fire('Not saved', '', 'info')
          }
        });
      }
      else {
        Swal.fire('Please check the value')
      }
    }
    catch(e)
    {
      displayErrorToast(e);
    } 
  }

    const ShowOrderDetails = () => {
      if (inputs.plant_id){
        setCheckedIds([])
      getSalesOrdersForInvoice(cookies,inputs.plant_id)
      .then (sales_order_list => {
        setAllSalesOrders(sales_order_list.sales_order_list.filter(obj => obj.status))
          const tableData = sales_order_list.sales_order_list
              .map(so_dtl=> ({
                  id :so_dtl.id,
                  order_no:so_dtl.sales_order.prefix+so_dtl.sales_order.order_no,  
                  order_date: so_dtl.sales_order.order_date,                             
                  customer:so_dtl.sales_order.consignee.customer.name, 
                  company:so_dtl.sales_order.company.name,
                  project_name:so_dtl.sales_order.consignee.project_name,
                  grade:so_dtl.product.name,
                  delivery_mode:so_dtl.delivery_mode,
                  total_dc_qty:so_dtl.total_dc_qty,
                  total_accepted_qty:so_dtl.total_accepted_qty,
                  so_rate:so_dtl.rate,
                  isChecked:false
              }));
              setData({
                  records: tableData
              });
              setIsLoading(false);
              setShowModal(true)
      })}
      else{
        Swal.fire("Please choose Plant.", '', 'info')
      }
  }

  const Close = () => {
    console.log(JSON.stringify(checkedIds)+"checkedIds Close)");
    setShowModal(false)
    if ((inputs.plant_id) && (checkedIds.length>0)){
      getDeliveryChallansForInvoice(cookies,inputs.plant_id,checkedIds.join(','))
      .then(response => {
        setIsLoading(false);
        setDcList(response.delivery_challan_list)
        response.delivery_challan_list.map(dc => {
        setInputs(values => ({...values,
          sales_order_id: dc.work_schedule.sales_order_detail.sales_order.id,
          company_id:dc.work_schedule.sales_order_detail.sales_order.company.id,
          customer_id:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.id,
          consignee_id:dc.work_schedule.sales_order_detail.sales_order.consignee.id,
          sales_order_no: dc.work_schedule.sales_order_detail.sales_order.prefix+dc.work_schedule.sales_order_detail.sales_order.order_no,
          sales_order_date: getDisplayDate(dc.work_schedule.sales_order_detail.sales_order.order_date),
          is_tcs_applicable:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.is_tcs_applicable
      }))
      // getCustomerMainDetails(cookies,dc.work_schedule.sales_order_detail.sales_order.consignee.customer.id)    
      //     .then(customerObject => {              //Set the details of the customer in the form fields
      //         setSelectedCustomer(customerObject)
      //         })
      // getCustomerSiteDetails(cookies,dc.work_schedule.sales_order_detail.sales_order.consignee.id)
      // .then(customerObject => {                     //Set the details of the customer in the form fields
      //     setSelectedSite(customerObject);
      // });
      getSalesOrderDetails(cookies,dc.work_schedule.sales_order_detail.sales_order.id)
        .then(salesorder =>{
          setSelectedSalesOrder(salesorder)
      })
    })
        // setInputs(values => ({...values, ['delivery_challan_no']: delivery_challan.delivery_challan_no,['batch_no']:delivery_challan.batch_no,['prefix']:delivery_challan.prefix}));
      })
      .catch((error) =>
        {
            setIsLoading(false);
          console.log(error.response.data);
          displayError(error.response.data," Failed");
        })
        // setInvdtls(allSalesOrders.filter(so => ))
    }

}
const columns =
    useMemo(
        () => [
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS",
                size:40
            },
            {
                fieldName: 'order_no',
                headerName: 'Order No',
                size:40
            },
            {
                fieldName: 'order_date',
                headerName: 'Order Date',
                size:50
            },
            {
                fieldName: "company",
                headerName: 'Company',
                size:180
            },
            {
                fieldName:"customer",
                headerName: 'Customer',
                size:180
            },
            {
                fieldName:"project_name",
                headerName: 'Project Name',
                size:180
            },
            {
              fieldName:"grade",
              headerName: 'Grade',
              size:180
            },
            {
              fieldName:"so_rate",
              headerName: 'Rate',
              size:180
            },
            {
              fieldName:"delivery_mode",
              headerName: 'Delivery Mode',
              size:180
            },
            {
              fieldName:"total_dc_qty",
              headerName: 'Dc Qty',
              size:180
            },
            {
            fieldName:"total_accepted_qty",
            headerName: 'Accepted Qty',
            size:180
            }
        ],
    [],);

    const salesorderno = () => {
      const plant = Dc.dcSoNo
      try{
          if (plant) {
            navigate(`/DeliveryChallan/${plant}`)
          }
          else {
            Swal.fire({html:`, <div style='border:1px solid black'><table align='center' width='100%'><tr><td colspan="2"style="background-color: blue"><h6 style="color:white;">SALESORDER DETAILS</h6></td></tr><tr><td align='right' style='font-size:14px' width='50%'>SALESORDER DATE :</td><td align='left' style='font-size:14px'>1-2-23</td></tr><tr><td align='right' style='font-size:14px'>PURCHASE ORDER NO :</td><td align='left' style='font-size:14px'>1001</td></tr><tr><td align='right' style='font-size:14px'>PURCHASE ORDER DATE:</td><td align='left' style='font-size:14px'>2-3-2023</td></tr><tr><td align='right' style='font-size:14px'>PAYMENT TERMS :</td><td align='left' style='font-size:14px'>3000</td></tr><tr><td align='right' style='font-size:14px'>MODE OF TRANSPORT :</td><td align='left' style='font-size:14px'>OWN</td></tr><tr><td align='right' style='font-size:14px'>IS THIS TRIAL :</td><td align='left' style='font-size:14px'>NO</td></tr><tr><td align='right' style='font-size:14px'>IS DC GRADE AND INVOICE GRADE SAME? :</td><td align='left' style='font-size:14x'>YES</td></tr><tr><td align='right' style='font-size:14px'>REMARKS :</td><td align='left' style='font-size:14px'>dcSoDt</td></tr></table></div>`}, '', 'info')   
        
          }
        }
        catch(e)
        {
          displayErrorToast(e);
        }  
    }


    const Cancel = () => {
        setInputs(() => "")
        setRowsData([])
      }
      const view = () => {
        navigate('/SalesPostingTable')
      } 
    
      const Back = () => {
        navigate('/Home')
      }

      const updateInvoiceNumberAndPrefix = (prefix, serial_no) => {

        setInputs(values => ({...values,
            ['invoice_num_prefix']:(prefix+serial_no),
            ['invoice_no']:serial_no,
            ['prefix']:prefix}));
      }

return (
        <>
         <div id="outer-container"  className="App" > 
         <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
         <ErrorToast/>
         <LoadingOverlay inProgress={inProgress}/>
         <div id="page-wrap">
         <form onSubmit={handleSubmit} > 
         <div id="header">
              <h3 className = "text font-weight-bold page-title" >TAX INVOICE </h3>
          </div>
          <FloatingControls tableLink="/CompanyTable" onCancel={Cancel} enableCancel={true}/>
          <div className="container">
          <Row>
                <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
                <label htmlFor="plant_id" className="form-group col-sm-5 text-right">Plant Location</label>
                <select id="plant_id" name="plant_id" className="form-control col-sm-6  browser-default custom-select  mandatory-form-control"  required onChange={handleChange} value={inputs.plant_id || ""}>
                            <option value="">Select Plant</option>
                            {plants.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                </select><br/>
                <label htmlFor="sales_order_no" className="form-group col-sm-5 text-right">Sales order No</label>
                    <input type="text" className="form-control col-sm-6"   id="sales_order_no" value={inputs.sales_order_no || ""} disabled={true} style={{cursor: "not-allowed" }} /><br />
                    <QuestionIcon onClickHandler={ShowOrderDetails}/><br />  
                   
                    <label htmlFor="sales_order_date" className="form-group col-sm-5 text-right">Sales order Date</label>
                    <input type="date" className="form-control col-sm-6"   id="sales_order_date" value={inputs.sales_order_date || ""} style={{cursor: "not-allowed" }} disabled={true}/><br />

                    <label htmlFor="customer_id" className="form-group col-sm-5 text-right">Billing Name</label>
                    <select  id="customer_id" name="customer_id" className="form-control col-sm-6"   value={inputs.customer_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                        <option value=""></option>
                                {customers.map((item) => (
                                        <option key={item.value} value={item.value}>
                                        {item.label}
                                        </option>
                                    ))}
                    </select><br />

                    <SummaryIcon onClickHandler={showCustomerDetails}/>
                    <label htmlFor="consignee_id" className="form-group col-sm-5 text-right">Ship to</label>
                    <select  id="consignee_id" name="consignee_id" className="form-control col-sm-6"  value={inputs.consignee_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                        <option value=""></option>
                                {sites.map((item) => (
                                        <option key={item.value} value={item.value}>
                                        {item.label}
                                        </option>
                                    ))}
                            
                    </select><br />
                    <SummaryIcon onClickHandler={showSiteDetails}/>
                    
                  <br />
                  </div>

                  </Col>
                  <Col xl={6} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                    {/* <label htmlFor="billname" className="form-group col-sm-5 text-right">Invoice No.</label>
                    <input type="text" className="form-control col-sm-6"   id="billname" value={inputs.billname || ""}readOnly={true} style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="invoice_date" className="form-group col-sm-5 text-right">Invoice Date/Time</label>
                    <input type="date" className="form-control col-sm-3 text-right" name="invoice_date" onChange={handleChange} value={inputs.invoice_date || ""} id="invoice_date"/>
                    <input type="time" id="invoice_time"  name="invoice_time"  value={inputs.invoice_time || ""} className="form-control col-sm-3" disabled={true} style={{cursor: "not-allowed"}}/>  */}
                    <NumberSetting 
                        handleDateChange={handleInvoiceDetails}
                        serial_no={inputs.invoice_no} 
                        creation_date={inputs.invoice_date}
                        creation_time={inputs.invoice_time}
                        prefix={inputs.prefix}
                        company_id={inputs.plant_id}
                        voucher_type={"invoice"}
                        handleNumberAndPrefixUpdate={updateInvoiceNumberAndPrefix}
                        cookies={cookies}
                        serial_no_title={'Invoice No'}
                        hideTime={false}/>

                    <label htmlFor="invoice_time" className="form-group col-sm-4 text-right">Invoice Time</label>
                    <input type="time" id="invoice_time"  name="invoice_time"  value={inputs.invoice_time || ""} className="form-control col-sm-7" disabled={true} style={{cursor: "not-allowed"}}/>  
                  
                    <label htmlFor="is_tcs_applicable" className="form-group col-sm-4 text-right">Is TCS Applicable </label>
                                <select name="is_tcs_applicable" id="is_tcs_applicable"  value={inputs.is_tcs_applicable} className=" form-control col-sm-7 "  disabled={true} style={{cursor: "not-allowed"}} >
                                 <option value=""></option> 
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>
                                <ViewOnlyTextbox 
                    name="project_name" 
                    value={selectedSalesOrder?.consignee?.project_name|| ""}
                    labelClassName="form-group col-sm-4 text-right"
                    label="Project Name"
                    inputClassName="form-control col-sm-7"
                                    /> 
                    <label htmlFor="is_einvoice_needed" className="form-group col-sm-4 text-right">E - invoice</label>
                                <select name="is_einvoice_needed" id="is_einvoice_needed" onChange={handleChange} value={inputs.is_einvoice_needed || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required >
                                <option value="">Please select</option>
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>
                   
                   
                    <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                                        <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                                            <div className="modal-content">
                                                <div className="modal-body display">
                                                    <div className="container item-list-table-container">
                                                        <ReactTablePopUpMultiSelect
                                                            title='List of Group Form'
                                                            columns={columns}
                                                            data={data.records}
                                                            onRowSubmit={handleChangeSalesOrderDetails}
                                                            isLoading={isLoading}
                                                            onClose = {Close}
                                                            // isChecked={isChecked}
                                                        />
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                     </div>

                    </Col>

                  </Row>


                  <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)",border:'4px solid rgb(235, 73, 73)'}}>
                    <thead>
                        <tr style={{backgroundColor:"RGB(188,232,253)"}}>
                            <th style={{display:"none"}}>Id</th>
                            <th>Sl No</th>
                            <th>Product Code</th>
                            <th>DC Description</th>
                            <th>Invoice Description</th>
                            <th>Unit</th>
                            <th>Mode Of Delivery</th>
                            <th>DC Qty</th>
                            <th>Order Qty</th>
                            <th>Complete Qty</th>
                            <th>Balance Qty</th>
                            <th>Acc/Invoice Qty</th>
                            <th>Basic Rate</th>
                            <th>Taxable Amount</th>
                            <th>GST %</th>
                            <th>CGST Amount</th>
                            <th>SGST Amount</th>
                            <th>IGST Amount</th>
                            <th>GST Amount</th>
                            <th>Gross Amount</th>
                            <th>Subgroup Category</th>
                            <th>CGST Percent</th>
                            <th>SGST Percent</th>
                            <th>IGST Percent</th>
                            <th>IGST Percent</th>
                        </tr>
                    </thead>
                    <tbody >
                    <TableRowsSalesPosting rowsData={rowsData}  handleChange4={handleChange4}   />
                    </tbody>
                </table>
            </div> 
            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"white",border:'4px solid rgb(235, 73, 73)'}}>
                  
                  <tr >
                    <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Taxable Amount </b></td>
                    <td colspan="4"><input type="text" className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                    <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>CGST Amount </b></td>
                    <td colspan="4"><input type="text" className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                </tr>                          
               <tr>
                <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total GST Amount  </b></td>
                <td colspan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b> SGST Amount  </b></td>
                <td colspan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                
                </tr>
                <tr>
               <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Gross Amount  </b></td>
               <td colspan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
               <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>IGST Amount  </b></td>
                <td colspan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                </tr>
                <tr>
                <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Round off  </b></td>
                <td colspan="4"><input type="text"  className='form-control'style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                <td colspan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>TCS @ %/ TCS Amount  </b></td>
                <td colspan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                </tr>
                <tr>
                <td colspan="14"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Invoice Amount  </b></td>
                <td colspan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
                
                </tr>
       
                </table>
                </div>

                <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)",border:'4px solid rgb(235, 73, 73)'}}>
                    <thead>
                        <tr style={{backgroundColor:"RGB(188,232,253)"}}>
                            <th style={{display:"none"}}>Id</th>
                            <th>Action</th>
                            <th>Check out No.</th>
                            <th>DC No.</th>
                            <th>DC Date</th>
                            <th>SO No.</th>
                            <th>SO Date</th>
                            <th>Grade</th>
                            <th>Unit</th>
                            <th>Mode of Delivery</th>
                            <th>DC Qty</th>
                            <th>Accepted Qty</th>
                            <th>Sales order Rate</th>
                            <th>Basic Rate</th>
                            <th>CGST Percent</th>
                            <th>SGST Percent</th>
                            <th>IGST Percent</th>
                            <th>Tax Name</th>
                            <th>Customer's Status</th>
                            <th>DC's Status</th>
                            <th>Vehicle No.</th>
                            <th>Product Code</th>
                            <th>SO DTL Id</th>
                            <th>SO Order Qty</th>
                            <th>Completed Qty</th>
                        </tr>
                    </thead>
                    <tbody >
                    <TableRowsSalesPosting rowsData={rowsData}  handleChange4={handleChange4}   />
                    </tbody>
                </table>
            </div> 

            </div>

            <div className="footer text-center">
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_SalesPosting_add === true)) &&(
                    <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> 
                )}&nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_SalesPosting_add === true)) &&(
                    <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button>
                )}&nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_SalesPsoting_view === true)|| (category['myCategory'][0].Is_SalesPosting_edit === true) || (category['myCategory'][0].Is_SalesPosting_delete === true)) &&(  
                    <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                )}&nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>


         </form>
            </div>

         </div>


</>
    );
  }
  export default SalesInvoice;
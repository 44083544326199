import {getAllSalesPostings} from '../../services/SalesPostingServices'
import Swal from "sweetalert2";
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import {parseBooleanToString} from '../../helpers';
export function checkForDuplicateSalesPostings(name, value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllSalesPostings(cookies)
      .then((data) => {

        let isUserExists = false;
        for(var i=0; i<data.salesrep_data.length; i++)    //Same Sales Representative Name mentioned it denoted already exist.
        {
          if((data.salesrep_data[i].entity_name) === value.trim().toUpperCase()
            && parseInt(existingId) !== data.salesrep_data[i].id){   
            
            console.log(`User exists with the name - ${value}`) 
            isUserExists = true;
            break;
          }
        }
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }
 
  export function showBillingPop(selectedCustomer){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'BILLING DETAILS',
          [
            {label: 'BILLING NAME', value: selectedCustomer.name},
          {label: 'ADDRESS', value: selectedCustomer.address_1},
          {value: selectedCustomer.address_2},
          {value: selectedCustomer.address_3},
          {label: 'STATE', value: selectedCustomer.state},
          {label: 'GST', value: selectedCustomer.gst_no},
          {label: 'PAN', value: selectedCustomer.pan_no},
          {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name},
          ])
  }, '', 'info');
  }
  export function showCustomerSitePop(consignee){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'DELIVERY DETAILS',
          [
            {label: 'DELIVERY NAME', value: consignee.name},
            {label: 'ADDRESS', value: consignee.address_1},
            {value: consignee.address_2},
            {value: consignee.address_3},
            {label: 'STATE', value: consignee.state},
          {label: 'GST', value: consignee.gst_no},
          {label: 'PAN', value: consignee.pan_no},
          ])
  }, '', 'info');   

  }
    
  
    
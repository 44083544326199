import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col} from "reactstrap";
import { displayError } from "../../helpers";
import {  DatePicker } from 'antd';
import moment  from 'moment';
import { getAllDeliveryChallans, deleteDeliveryChallan ,printBatchReport,printDeliveryChallan } from "../../services/DeliveryChallanServices";
const {RangePicker} = DatePicker;
function DeliveryChallanTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const [dates,setDates]=useState({}) 
    const left_pinned = ["delivery_challan_no","delivery_challan_date"]
    const right_pinned = ["id"]
    const columns = useMemo(
        () => [
            {
                fieldName: "plant",
                headerName: 'Plant'
            },
            {
                fieldName: "delivery_challan_no",
                headerName: 'DC.No'
            },
            {
                fieldName: "delivery_challan_date",
                headerName: 'DC Date'
            },
            {
                fieldName: "delivery_challan_time",
                headerName: 'DC Time'
            },
            {
                fieldName: "order_no",
                headerName: 'SO No'
            },
            {
                fieldName: "workschedule_no",
                headerName: 'WS No'
            },
            {
                fieldName: 'customer_name', 
                headerName: 'Customer Name'
            },
           
            {
                fieldName: "consignee_projectname",
                headerName: 'Customer Site/Project'
            },
            {
                fieldName: 'production_plant', 
                headerName: 'Production Plant'
            },
            {
                fieldName: 'grade', 
                headerName: 'Grade'
            },
      
            {
                fieldName: "production_qty",
                headerName: 'Production Qty'
            },
            {
                fieldName: "purchase_qty",
                headerName: 'Purchase Qty'
            },
            {
                fieldName: "received_qty",
                headerName: 'Received Qty'
            },
            {
                fieldName: "other_qty",
                headerName: 'Other Qty'
            },
            {
                fieldName: "client_adjust_qty",
                headerName: 'Client Qty'
            },
            {
                fieldName: "dc_qty",
                headerName: 'DC Qty'
            },
            {
                fieldName: "invoice_qty",
                headerName: 'Invoice Qty'
            },
            {
                fieldName: "customer_vehicle_no",
                headerName: 'Vehicle No'
            },
            {
                fieldName: "delivery_mode",
                headerName: 'Mode of Delivery'
            },
     
            {
                fieldName: "status",
                headerName: 'DC Status'
            },
            
            {
                fieldName: "dc_invioce",
                headerName: 'DC Invoice Status'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

const onEditDeliveryChallan = (deliveryId, event) => {
    
    navigate(`/DeliveryChallanEdit/${deliveryId}/`);
};

const onDeleteDeliveryChallan = (deliveryId, event) => {

    event.preventDefault();
    Swal.fire({title: 'Are you sure to Delete?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,  
    cancelButtonText: `No`,
    }).then((result) => {  
        if (result.isConfirmed) { 

        setIsLoading(true);
        deleteDeliveryChallan(cookies, deliveryId)
        .then(response => {
            console.log(`Delivery with id ${deliveryId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
        })
        .catch(error => {
            setRefreshKey(oldKey => oldKey +1);
            console.log(error.response.data);
            displayError(error.response.data,'Delete Failed');
        });  
        
        } else if (result.isDismissed) {    
        Swal.fire('Not Deleted', '', 'info')  
    }
    });
};

const onPrintBr = (deliveryId, event) => {
    event.preventDefault();
    setIsLoading(true);
    printBatchReport(cookies, deliveryId)
    .then(response => {
        const file = new Blob([response], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
        setIsLoading(false);
    })
    .catch(error => {
        // setRefreshKey(oldKey => oldKey +1);
        console.log(error);
        displayError(error,'BR print Failed');
        setIsLoading(false);
    });  
};

const onPrintDeliveryChallan = (deliveryId, event) => {
    event.preventDefault();
    setIsLoading(true);
    printDeliveryChallan(cookies, deliveryId)
    .then(response => {
        const file = new Blob([response], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
        setIsLoading(false);
    })
    .catch(error => {
        // setRefreshKey(oldKey => oldKey +1);
        console.log(error);
        displayError(error,'Print Failed');
        setIsLoading(false);
    });  
};

const handleCSVExport = (event) => {
    console.log('CSV Export');
    exportAsCSV(
        columns
        .filter(col => col.headerName !== 'ROW_ACTIONS')
        .map(c => c.headerName),
        data.records.map(dc => {

            const csvRow = {
                plant:dc.plant,
                delivery_challan_no: dc.delivery_challan_no,
                delivery_challan_date:dc.delivery_challan_date,
                delivery_challan_time:dc.delivery_challan_time,
                batch_no:dc.batch_no,
                order_no:dc.order_no,
                workschedule_no:dc.workschedule_no,
                customer_name:dc.customer_name,
                consignee_projectname:dc.consignee_projectname,
                production_plant:dc.production_plant,
                production_qty:dc.production_qty,
                purchase_qty:dc.purchase_qty,
                other_qty:dc.other_qty,
                received_qty:dc.received_qty,
                client_adjust_qty:dc.client_adjust_qty,
                dc_qty:dc.dc_qty,
                invoice_qty:dc.invoice_qty,
                customer_vehicle_no:dc.customer_vehicle_no,
                delivery_mode:dc.delivery_mode,
                grade:dc.grade,
                dc_invioce:dc.dc_invioce,
                status:dc.status? "Active":"Cancelled",
                // id: dc.id,    
            }
            return csvRow;
            }));
};

const handlePDFExport = (event) => {
    console.log('PDF Export');
    exportAsPDF(
        'List of Delivery Challan',
        columns
        .filter(col => col.headerName !== 'ROW_ACTIONS')
        .map(col=>({header: col.headerName, dataKey: col.fieldName })),
        data.records.map(dc => ({
            plant:dc.plant,
            delivery_challan_no: dc.delivery_challan_no,
            delivery_challan_date:dc.delivery_challan_date,
            delivery_challan_time:dc.delivery_challan_time,
            batch_no:dc.batch_no,
            order_no:dc.order_no,
            workschedule_no:dc.workschedule_no,
            customer_name:dc.customer_name,
            consignee_projectname:dc.consignee_projectname,
            production_plant:dc.production_plant,
            production_qty:dc.production_qty,
            purchase_qty:dc.purchase_qty,
            other_qty:dc.other_qty,
            received_qty:dc.received_qty,
            client_adjust_qty:dc.client_adjust_qty,
            dc_qty:dc.dc_qty,
            invoice_qty:dc.invoice_qty,
            customer_vehicle_no:dc.customer_vehicle_no,
            delivery_mode:dc.delivery_mode,
            grade:dc.grade,
            dc_invioce:dc.dc_invioce,
            status:dc.status? "Active":"Cancelled",
            id: dc.id,    
        })),
        'DeliveryChallanList.pdf'
    );
};

useEffect(() => {

    getAllDeliveryChallans(cookies)
    .then( deliverychallanList => {
        const tableData = deliverychallanList.delivery_challan_list
        .map(dc => ({
            plant:dc.work_schedule.plant.alias_name,
            delivery_challan_no: dc.delivery_challan_no,
            delivery_challan_date:dc.delivery_challan_date,
            delivery_challan_time:dc.delivery_challan_time,
            batch_no:dc.batch_no,
            order_no:dc.work_schedule.sales_order_detail.sales_order.order_no,
            workschedule_no:dc.work_schedule.schedule_no,
            customer_name:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.name,
            consignee_projectname:dc.work_schedule.sales_order_detail.sales_order.consignee.project_name,
            production_plant:dc.production_plant.name,
            production_qty:dc.production_qty,
            purchase_qty:dc.purchase_qty,
            other_qty:dc.other_qty,
            received_qty:dc.received_qty,
            client_adjust_qty:dc.client_adjust_qty,
            dc_qty:dc.dc_qty,
            invoice_qty:dc.invoice.invoice_qty,
            customer_vehicle_no:dc.vehicle.name?dc.vehicle.name:dc.customer_vehicle_no,
            delivery_mode:dc.work_schedule.delivery_mode,
            grade:dc.work_schedule.sales_order_detail.product.name,
            dc_invioce:dc.status === false ? "Cancelled": dc.invoice.invioce_no? dc.invoice.invioce_no: "Pending",
            //dc.invoice.invioce_no?dc.invoice.invioce_no:"Pending",
            status:dc.status? "Active":"Cancelled",
            id:dc.id,
            is_batch_report_needed:dc.work_schedule.sales_order_detail.sales_order.company.is_batch_report_needed?"Yes":"No",
            hide_row_edit:dc.work_schedule.is_schedule_completed?"Yes":"No",
            hide_row_print:dc.checkout_no?"No":"Yes"
        }));

        setData({
            total: data.total,
            records: tableData
        });
        setIsLoading(false);
    });
    }, [refreshKey]);

return (

    <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <div id="page-wrap">
        <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Delivery Challan</h3>
        
            <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of DeliveryChallan'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditDeliveryChallan}
                            onRowDelete={onDeleteDeliveryChallan}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            hideDelete={true}
                            // showBrDownload={true}
                            onRowBrPrint={onPrintBr}
                            onRowPrint={onPrintDeliveryChallan}
                            hidePrint ={false}
                            left_pinned={left_pinned}
                            right_pinned={right_pinned}
                        />
                </div>
            </div> 
        </div>
        <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/DeliveryChallan")}}>Back</button>
    </div>
);

}



export default DeliveryChallanTable;
import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col,Button,} from "reactstrap";
import { default as ReactSelect } from "react-select";
import {getDispatchReport} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import { getAllPlants} from '../../services/PlantServices';
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import {getAllCustomerSites} from '../../services/CustomerSiteServices';
import { getAllProducts } from "../../services/ProductServices";
import { getAllEquipments } from '../../services/EquipmentServices';
import { getCustomerVehicles } from '../../services/DeliveryChallanServices';
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import {HandleSelect,ValueContainer,Option} from "../../components/MultiSelectControls";
const {RangePicker} = DatePicker;

function DispatchReportTable() 
{
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedSalesrep, setSelectedSalesRep] = useState([]);
    const [salesreps, setSalesreps] = useState([]);
    const [selectedSite, setSelectedSite] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState([]);
    const [grades, setGrades] = useState([]);
    const [inputs, setInputs] = useState({});

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
                setSelectedPlant(values =>[...values,...plants, ...plantsWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                const customers = customerList.customer_list.map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
                setSelectedCustomer(values =>[...values,...customers, ...customersWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
    getAllCustomerSites(cookies)
    .then (
        siteList => {
            const sites = siteList.consignee_list.map(
                site => {
                    return { value: site.id, label: site.name }
                }
            );
            setSites(sites);
            setSelectedSite(values =>[...values,...sites, ...sitesWithSelectAll]);
        }
    )
    }, []);

    React.useEffect(() => {
        getAllSalesRepresentatives(cookies)
        .then (
            salesRepList => {
                const salesrep = salesRepList.salesrep_data.map(
                sales => {
                        return { value: sales.id, label: sales.entity_name }
                    }
                );
                //console.log(JSON.stringify(salesRepList)+"salesRepList")
                setSalesreps(salesrep);
                setSelectedSalesRep(values =>[...values,...salesrep, ...salesrepsWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getCustomerVehicles(cookies)
        .then (
            equipment => {
                const customer_vehicles = equipment.customer_vehicles_list.map(
                    equip => {
                        return { value: equip, label: equip}
                    }
                );
                setVehicles(customer_vehicles);
                setSelectedVehicle(customer_vehicles);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllEquipments(cookies)
        .then (
            equipmentObject => {
                const equipments = equipmentObject.equipment_list.filter(obj => ((obj.equip_type.name === 'TRANSIT MIXER'))).map(
                    equipment => {
                        return { value: equipment.id, label: equipment.equip_name }
                    }
                );
                setVehicles(values =>[...values, ...equipments]);
                setSelectedVehicle(values =>[...values,...equipments, ...vehiclesWithSelectAll]);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllProducts(cookies)
        .then (
            productList => {
                const products = productList.product_list.map(
                    product => {
                        return { value: product.id, label: product.name}
                    }
                )
                setGrades(products);
                setSelectedGrade(values =>[...values,...products, ...gradesWithSelectAll]);
            }
        )
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name+value+"name and value")
        setInputs(values => ({...values, [name]: event.target.value}))
    }

    // Add a "Select All" option to the plants array
    const plantsWithSelectAll = [
        { value: "select-all", label: "Select All plants" },
        ...plants,
    ];

    // Add a "Select All" option to the customers array
    const customersWithSelectAll = [
        { value: "select-all", label: "Select All customers" },
        ...customers,
    ];
    
    // Add a "Select All" option to the sites array
    const sitesWithSelectAll = [
        { value: "select-all", label: "Select All sites" },
        ...sites,
    ];
    
    // Add a "Select All" option to the salesreps array
    const salesrepsWithSelectAll = [
        { value: "select-all", label: "Select All sales representatives" },
        ...salesreps,
    ];  
    
    // Add a "Select All" option to the vehicles array
    const vehiclesWithSelectAll = [
        { value: "select-all", label: "Select All vehicles" },
        ...vehicles,
    ];

    // Add a "Select All" option to the grades array
    const gradesWithSelectAll = [
        { value: "select-all", label: "Select All grades" },
        ...grades,
    ];
      
    const onPlantSelect = (selected) => {
        HandleSelect(selected,selectedPlant,setSelectedPlant, plants, plantsWithSelectAll);
    };
    
    const onCustomerSelect = (selected) => {
        HandleSelect(selected,selectedCustomer, setSelectedCustomer, customers, customersWithSelectAll);
    };
    
    const onSiteSelect = (selected) => {
        HandleSelect(selected,selectedSite, setSelectedSite, sites, sitesWithSelectAll);
    };
        
    const onSalesRepSelect = (selected) => {
        HandleSelect(selected,selectedSalesrep, setSelectedSalesRep, salesreps, salesrepsWithSelectAll);
    };

    const onVehicleSelect = (selected) => {
        HandleSelect(selected,selectedVehicle, setSelectedVehicle, vehicles, vehiclesWithSelectAll);
    };
    
    const onGradeSelect = (selected) => {
        HandleSelect(selected,selectedGrade, setSelectedGrade, grades, gradesWithSelectAll);
    };

    const areAllPlantsSelected = () => {
        // Check if all plants are selected
        return plants.every((plant) =>
          selectedPlant ? selectedPlant.some((selected) => selected.value === plant.value) : false
        );
    };

    const areAllCustomersSelected = () => {
        // Check if all customers are selected
        return customers.every((customer) =>
            selectedCustomer ? selectedCustomer.some((selected) => selected.value === customer.value) : false
        );
    };

    const areAllConsigneesSelected = () => {
        // Check if all consignees are selected
        return sites.every((consignee) =>
        selectedSite ? selectedSite.some((selected) => selected.value === consignee.value) : false
        );
    };

    const areAllSalesRepsSelected = () => {
        // Check if all salesreps are selected
        return salesreps.every((salesrep) =>
        selectedSalesrep ? selectedSalesrep.some((selected) => selected.value === salesrep.value) : false
        );
    };
    
    const areAllVehiclesSelected = () => {
        // Check if all vehicles are selected
        return vehicles.every((vehicle) =>
        selectedVehicle ? selectedVehicle.some((selected) => selected.value === vehicle.value) : false
        );
    };
    
    const areAllGradesSelected = () => {
        // Check if all grades are selected
        return grades.every((grade) =>
        selectedGrade ? selectedGrade.some((selected) => selected.value === grade.value) : false
        );
    };

    const Home = () => {
        navigate('/Home')
    }

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant_name', 
                headerName: 'Plant Name'
            },
            {
                fieldName: 'plant_alias', 
                headerName: 'Plant Alias'
            },
            {
                fieldName: 'production_plant_name', 
                headerName: 'Production Plant Name'
            },
            {
                fieldName: "delivery_challan_date",
                headerName: 'Date'
            },
            {
                fieldName: 'delivery_challan_no', 
                headerName: 'DC Number'
            },
            {
                fieldName: "batch_no",
                headerName: 'Batch Number'
            },
            {
                fieldName: 'delivery_challan_time', 
                headerName: 'Time'
            },
            {
                fieldName: "sales_order_no",
                headerName: 'SO Number'
            },
            {
                fieldName: "purchase_order_no",
                headerName: 'PO Number'
            },
            {
                fieldName: "invoice_number",
                headerName: 'Invoice Number'
            },
            {
                fieldName: 'customer_name', 
                headerName: 'Customer Name'
            },
            {
                fieldName: "project_name",
                headerName: 'Site/Project Name'
            },
            {
                fieldName: 'dc_grade', 
                headerName: 'DC Grade'
            },
            {
                fieldName: 'dc_qty', 
                headerName: 'DC Qty'
            },
            {
                fieldName: 'rate', 
                headerName: 'Rate'
            },
            {
                fieldName: 'amount', 
                headerName: 'Amount'
            },
            {
                fieldName: 'delivery_mode', 
                headerName: 'Mode of Delivery'
            },
            {
                fieldName: 'vehicle_no', 
                headerName: 'Vehicle No'
            },
            {
                fieldName: "salesrep_name",
                headerName: 'Marketting'
            },
            {
                fieldName: "driver_name",
                headerName: 'Driver Name'
            },
            {
                fieldName: "accepted_qty",
                headerName: 'Accepted Qty'
            },
            {
                fieldName: "customer_dc_status",
                headerName: 'DC Status'
            },
            {
                fieldName: "total_km",
                headerName: 'Total Km'
            },
            {
                fieldName: "net_weight",
                headerName: 'Net weight'
            },
            {
                fieldName: "gang",
                headerName: 'Gang'
            },
            {
                fieldName: "pump",
                headerName: 'Pump'
            }
        ],
        [],);
   
    const handleCSVExport = (event) => {
        // console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            // .filter(col => col.headerName == 'Plant Name')
            .map(c => c.headerName),
            data.records.map(dc => {
                const csvRow = {
                    plant_name:(dc.plant_name != null )? dc.plant_name:'ram',
                    plant_alias: dc.plant_alias?dc.plant_alias:'ram',
                    production_plant_name: dc.production_plant_name?dc.production_plant_name:'ram',
                    delivery_challan_date: dc.delivery_challan_date?dc.delivery_challan_date: 'ram',
                    delivery_challan_no: dc.delivery_challan_no?dc.delivery_challan_no:'ram',
                    batch_no: dc.batch_no?dc.batch_no:'ram',
                    delivery_challan_time: dc.delivery_challan_time?dc.delivery_challan_time:'ram',
                    sales_order_no: dc.sales_order_no?dc.sales_order_no:'ram',
                    purchase_order_no:dc.purchase_order_no?dc.purchase_order_no : 'ram',
                    invoice_number: dc.invoice_number?dc.invoice_number:'ram',
                    customer_name:dc.customer_name ?dc.customer_name: 'ram',
                    project_name:dc.project_name?dc.project_name:'ram',
                    dc_grade:  dc.dc_grade?dc.dc_grade:'ram',
                    dc_qty: dc.dc_qty?dc.dc_qty :0,
                    rate: dc.rate?dc.rate :0,
                    amount:dc.amount?dc.amount:0,
                    delivery_mode: dc.delivery_mode?dc.delivery_mode:'ram',
                    vehicle_no: dc.vehicle_no?dc.vehicle_no: 'ram',
                    salesrep_name: dc.salesrep_name?dc.salesrep_name:'ram',
                    driver_name:  dc.driver_name?dc.driver_name:'ram',
                    accepted_qty:  dc.accepted_qty?dc.accepted_qty:0,
                    customer_dc_status: dc.customer_dc_status?dc.customer_dc_status:'ram',
                    total_km:  dc.total_km?dc.total_km:0,
                    net_weight:  dc.net_weight?dc.net_weight:0,
                    gang:dc.gang?dc.gang:'gang',
                    pump:dc.pump?dc.pump:'pump'
                }
                return csvRow;
                }))
    };

    const handlePDFExport = (event) => {
        // console.log('PDF Export');
        exportAsPDF(
            'Dispatch Report',
            columns
            .filter(col => ['Date', 'DC Number','Time','Customer Name','Site/Project Name','DC Grade','DC Qty','Rate','Amount','Vehicle No','Marketting'].includes(col.headerName))
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(dc => ({
                delivery_challan_no: dc.delivery_challan_no,
                delivery_challan_date: dc.delivery_challan_date,
                delivery_challan_time: dc.delivery_challan_time,
                customer_name:dc.customer_name,
                project_name:dc.project_name,
                salesrep_name: dc.salesrep_name,
                vehicle_no: dc.vehicle_no,
                dc_grade:  dc.dc_grade,
                dc_qty: dc.dc_qty,
                rate: dc.rate,
                amount:  dc.amount
            })),
            'Dispatch_report.pdf'
        );
    };

    const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
            plant_ids:areAllPlantsSelected()? '*' // Send '*' if all plants are selected
            : selectedPlant.map((plant) => plant.value).join(','),
            customer_ids:areAllCustomersSelected()? '*' // Send '*' if all customers are selected
            : selectedCustomer.map((customer) => customer.value).join(','),
            consignee_ids:areAllConsigneesSelected()? '*' // Send '*' if all consignees are selected
            : selectedSite.map((consignee) => consignee.value).join(','),
            sales_rep_ids:areAllSalesRepsSelected()? '*' // Send '*' if all salesreps are selected
            : selectedSalesrep.map((salesrep) => salesrep.value).join(','),
            vehicle_ids:areAllVehiclesSelected()? '*' // Send '*' if all vehicles are selected
            : selectedVehicle.map((vehicle) => vehicle.value).join(','),
            product_ids:areAllGradesSelected()? '*' // Send '*' if all grades are selected
            : selectedGrade.map((grade) => grade.value).join(','),
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY'),
            report_format:inputs.report_format
          }
    }

    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.plant_ids) && (inputs.customer_ids) && (inputs.consignee_ids) && (inputs.sales_rep_ids) && 
        (inputs.vehicle_ids) && (inputs.product_ids) &&
        (inputs.from_date) && (inputs.upto_date))
        {
            getDispatchReport(cookies, constructFormData())
            .then(response => {
                const tableData = response.dispatch_report_list
                .map(dc => ({
                    plant_name: dc.plant_name,
                    plant_alias: dc.plant_alias,
                    production_plant_name: dc.production_plant_name,
                    id: dc.id,
                    customer_id:dc.customer_id,
                    batch_no: dc.batch_no,
                    delivery_challan_no: dc.delivery_challan_no,
                    delivery_challan_date: dc.delivery_challan_date,
                    delivery_challan_time: dc.delivery_challan_time,
                    sales_order_no: dc.sales_order_no,
                    purchase_order_no:dc.purchase_order_no,
                    invoice_number: dc.invoice_number,
                    customer_name:dc.customer_name,
                    project_name:dc.project_name,
                    dc_grade:  dc.dc_grade,
                    dc_qty: dc.dc_qty,
                    rate: dc.rate,
                    amount:  dc.amount,
                    delivery_mode: dc.delivery_mode,
                    vehicle_no: dc.vehicle_no,
                    salesrep_name: dc.salesrep_name,
                    driver_name:  dc.driver_name,
                    accepted_qty:  dc.accepted_qty,
                    customer_dc_status: dc.customer_dc_status,
                    total_km:  dc.total_km,
                    net_weight:  dc.net_weight,
                    gang:dc.gang,
                    pump:dc.pump
                }));
                setData({
                    total: data.total,
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("plant,customer,consignee,sales_rep,vehicle,product,from_date,upto_date must not be empty.", "", "info");  
        }
    }

    return (
        // <div id="outer-container"  className="App" > 
        //     <div id="page-wrap">
            <>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container"> 
                       <Row> 
                            <Col xl={12} lg={12} md={12}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                    {/* <div style={{width: '12%'}} ></div> */}
                                        <ReactSelect
                                            options={plantsWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedPlant}
                                            onChange={onPlantSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please select Plant name"
                                        />
                                        <br/><br/>
                                    
                                        <ReactSelect
                                            options={customersWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedCustomer}
                                            onChange={onCustomerSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please select Customer"
                                        />
                                        <br/><br/>

                                        <ReactSelect
                                            options={sitesWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedSite}
                                            onChange={onSiteSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please Select Site"
                                        />
                                        <br/><br/>

                                    {/* <div style={{width: '12%'}} ></div> */}
                                        <ReactSelect
                                            options={salesrepsWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedSalesrep}
                                            onChange={onSalesRepSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please Select SalesRep"
                                        />
                                        <br/><br/>
                                    
                                        <ReactSelect
                                            options={vehiclesWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedVehicle}
                                            onChange={onVehicleSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please Select Vehicle"
                                        />
                                        {/* <br/>&nbsp;&nbsp;&nbsp; */}

                                        <ReactSelect
                                            options={gradesWithSelectAll}
                                            isMulti= {true}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{Option, ValueContainer}}
                                            isClearable={true}
                                            value={selectedGrade}
                                            onChange={onGradeSelect}
                                            className="react-select-dropdown col-sm-3"
                                            placeholder="Please Select Grade"
                                        />

                                        <RangePicker
                                            defaultValue={[initialFromDate, initialToDate]}
                                            format="DD-MM-YYYY"
                                            onChange={handleDateChange}
                                            style={{height:"40px",width:"270px"}}
                                        />
                                        &nbsp;&nbsp;&nbsp;

                                        <select className="browser-default custom-select col-sm-3"  onChange={handleChange} value={inputs.report_format || ""} name ="report_format"required style={{width:"200px"}} id="myData">
                                            <option value="">Please Select Reports</option>
                                            <option value="all">All</option>
                                            <option value="date_wise">Datewise</option>
                                            <option value="customer_wise">Customerwise</option>
                                        </select>&nbsp;&nbsp;  
                                            
                                        <Button  type="submit"
                                            style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",
                                            height:"30px",display: "flex",
                                            alignItems: "center",  
                                            justifyContent: "center"}}>
                                            <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                        </Button> 
                                </div>
                             </Col>
                        </Row> 
                    </div> 
                </form>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='CustomerData Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                    </div>
                </div> 
            </>
);
}

export default DispatchReportTable;